/************ Banner Css ************/
.banner {
  padding: 100px 0 35px 0;
  background-color: var(--theme-background-color);
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner h1 {
  font-size: 70px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1;
  text-align: center;
}

.banner h2 {
  display: block;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.2;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 90%;
}

.banner img {
  animation: updown 3.5s linear infinite;
  display: block;
  margin: auto;
}

@keyframes updown {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .banner h1 {
    font-size: 48px;
  }

  .banner .heading {
    margin-top: -30px;
  }

  .banner .dragon {
    width: 50%;
  }
}

@media (max-width: 575.98px) {
  .banner h1 {
    font-size: 32px;
  }

  .banner h2 {
    font-size: 18px;
  }

  .banner .heading {
    margin-top: -40px;
  }

  .banner .dragon {
    width: 45%;
  }
}
