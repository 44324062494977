/************ Contact Css ************/
.contact {
  background-color: var(--theme-content-color);
  color: var(--theme-background-color);
  position: relative;
  padding: 45px 0;
}

.contact h1 {
  font-size: 30px;
  padding-top: 20px;
  text-align: center;
}

.contact-border {
  border: var(--theme-border-color) solid 1.5px;
}

.contact img {
  width: 92%;
  padding-bottom: 30px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.contact form label {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  text-align: left;
  margin-left: 50px;
  width: 100%;
}

.contact form textarea {
  height: 120px;
}

.contact form input, .contact form textarea {
  width: 85%;
  background: var(--theme-background-color);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.8px;
  margin-bottom: 8px;
  padding: 14px 20px;
  width: 85%;
}

.contact form #submit-btn {
  background-color: var(--theme-border-color);
  border-color: var(--theme-border-color);
  border-radius: 20px;
  color: #000;
  font-size: 18px;
  font-weight: 700;
  margin: 10px 0 25px 0;
  width: 120px;
}

.contact form #submit-btn:hover {
  background-color: var(--theme-content-color);
}
