/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
  padding: 0 0;
  background-color: var(--theme-content-color);
}

nav.navbar a.navbar-brand {
  width: 15%;
}

nav.navbar .navbar-nav {
  padding-bottom: 15px;
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: var(--theme-background-color);
  letter-spacing: 0.8px;
  padding: 5px 25px;
  font-size: 22px;
  opacity: 0.75;
  text-align: center;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

.navbar-nav .navbar-link:hover {
  text-decoration: underline;
}

nav.navbar .navbar-toggler {
  border: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid var(--theme-border-color);
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: var(--theme-border-color);
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: var(--theme-background-color);
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: var(--theme-background-color);
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}
