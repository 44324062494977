.about {
  padding: 45px 0;
  position: relative;
}

.about h1 {
  font-size: 30px;
  text-align: center;
}

.about h2 {
  font-size: 24px;
  text-align: center;
  padding-top: 10px;
}

.about p {
  margin: auto;
  max-width: 600px;
  margin-bottom: 10px;
}

.about ul {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  padding-bottom: 15px;
}

.about img {
  padding: 10px;
}

.about-images-container {
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 768px) {
  .about-images-container {
    grid-template-columns: 1fr 1fr;
  }
}