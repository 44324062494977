/************ Custom Variables ************/
* {
  --theme-background-color: #FFF8E6;
  --theme-content-color: #a02200;
  --theme-border-color: #f3ff4a;
}

/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/fonts/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/fonts/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/fonts/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

main {
  background-color: var(--theme-background-color);
  color: #000;
  font-family: 'Centra', sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul { 
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

p.success {
  color: green;
}

p.danger {
  color: red;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}
